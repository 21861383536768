import React, { useContext,  useState } from 'react';
import Table from '../UI/Table';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import { AppContext } from '../../context/AppContext';

export default function AllTransaction() {

    
    const {  allData } = useContext(AppContext);
    
    const [Header,  ] = useState([
        "amount",
        "description",
        "category",
        "payment Method",
        "payment Type",
        "Withdrawal Off",
        "date",
        "user",
        "action"
    ]);
    
    const [searchText, setSearchText] = useState('');
    const itemsPerPage = 10;    
    const [transactions] = useState(allData);
    const [ setItemOffset] = useState(0);

    
    const filteredRows = transactions.filter(row =>
        Object.values(row).some(value =>
            value.toString().toLowerCase().includes(searchText.toLowerCase())
        )
    );

    const updatePagination = (offset) => {
        const endOffset = Math.min(offset + itemsPerPage, filteredRows.length);
        setCurrentItems(filteredRows.slice(offset, endOffset));
        setPageCount(Math.ceil(filteredRows.length / itemsPerPage));
    };

    const [currentItems, setCurrentItems] = useState(filteredRows.slice(0, itemsPerPage));
    const [pageCount, setPageCount] = useState(Math.ceil(filteredRows.length / itemsPerPage));

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % filteredRows.length;
        setItemOffset(newOffset);
        updatePagination(newOffset);
    };

   
    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
        const newFilteredRows = transactions.filter(row =>
            Object.values(row).some(value =>
                value.toString().toLowerCase().includes(e.target.value.toLowerCase())
            )
        );
        setPageCount(Math.ceil(newFilteredRows.length / itemsPerPage));
        updatePagination(0);
    };

    return (
            <div id="kt_content_container" className="container-xxl mb-5">
                <div className="card card-flush border-gray-300 shadow-sm">
                    <div className="card-header py-0">
                        <h3 className="card-title">
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor"
                                className="bi bi-list-task" viewBox="0 0 16 16">
                                <path fillRule="evenodd"
                                    d="M2 2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5H2zM3 3H2v1h1V3z" />
                                <path
                                    d="M5 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM5.5 7a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 4a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9z" />
                                <path fillRule="evenodd"
                                    d="M1.5 7a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5V7zM2 7h1v1H2V7zm0 3.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H2zm1 .5H2v1h1v-1z" />
                            </svg>&nbsp; Transaction List
                        </h3>

                        <div className="card-toolbar">
                            <div className="row">
                                <div className="col-md-6">
                                    <input
                                        type="text"
                                        id="searchInput"
                                        placeholder="Search...."
                                        value={searchText}
                                        onChange={handleSearchChange}
                                        className="form-control form-control-solid bg-gray text-dark fs-7"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Link to="/transaction" type="button" className="btn btn-sm btn-primary">
                                        ADD Transaction
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-body py-2">
                        <div className="table-responsive">
                            <Table
                                Header={Header}
                                transactionData={currentItems}
                                routeName="editTransaction"
                            />
                        </div>
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel="Next >"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            previousLabel="< Previous"
                            renderOnZeroPageCount={null}
                            containerClassName="pagination"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            activeClassName="active"
                        />
                    </div>
                </div>
            </div>
    );
}
