import React from "react";
import {  Button  } from './FormComponent';


export default function FormButton({onclick}) {
  return (
    <div>
      <br />
      <div align="center">
        <div className="row mb-5 column-gap-6">
          <div className="col d-flex justify-content-center gap-5">
            <Button
              type="submit"
              className="btn btn-success text-uppercase"
              buttonText="submit"
            />
            <Button
              type="button"
              onclick={onclick}
              className="btn btn-danger text-uppercase"
              buttonText="cancel"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
