import React, { useContext, useEffect } from 'react';
import { Chart } from "react-google-charts";
import { AppContext } from '../../context/AppContext';

function Charts() {
    const { token, pieChart, PieChartData } = useContext(AppContext);

    const tempData = {
        token: token,
        apikey: "Unique@1234567890@",
        Month: "11",
        Year: "2024"
    };

    useEffect(() => {
        PieChartData(tempData);
    }, [token]);

    const fallbackPieChart = [
        ["Category", "Amount"],
        ["Bigrock Domain", 1143.42],
        ["Freelancing", 30000.00],
        ["Leti-Deti", 14054.00],
        ["Nasto", 434.00],
        ["Other", 140.00],
    ];

    const chartData = pieChart.length > 1 ? pieChart : fallbackPieChart;
    // console.log(chartData);

    const options = {
        title: "Monthly Expense"
    };

    return (
        <div style={{
            height:'350px',
            background: 'transparent',
            borderRadius: '20px',
            borderBottomLeftRadius: '20px',
            overflow: 'hidden'
        }}>
            {chartData && (
                <Chart
                    chartType="PieChart"
                    data={chartData}
                    options={options}
                    width={"100%"}
                    height={"100%"}
                />
            )}
        </div>
    );
}

export default Charts;
