import React, { useContext, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import { TopbarCard } from '../UI/FinancialCard';
// import '../assets/css/style.bundle.css'


function TopBar() {

    const { total, currentMoney, creditDebit } = useContext(AppContext);
    // console.log(creditDebit);
    // const [total , setTotal] = useState(0); 

    // currentMoney.map((item)=>{
    //     setTotal(total+item.Current)
    // })


    return (
        <div id='kt_header' className='header align-items-stretch'>
            <div className='container-fluid d-flex flex-row align-items-stretch justify-content-between'>
                <div className='d-flex align-items-center d-lg-none ms-n2 me-2' title='Show aside menu'>
                    <div
                        className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
                        id='kt_aside_mobile_toggle'
                    >
                        <span className='svg-icon svg-icon-1'>
                            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                                <path d='M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z' fill='black' />
                                <path opacity='0.3' d='M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z' fill='black' />
                            </svg>
                        </span>
                    </div>
                </div>

                <div class='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
                    <a href='' class='d-lg-none'>
                        <img alt='Logo'
                            src='https://cqpplefitting.com/ad_cqpple/Admin/assets/LOGO/Png_UniqueIT-min.png'
                            class='h-30px' />

                    </a>
                </div>
                {currentMoney.map((item, index) => (
                    < TopbarCard
                        key={index}
                        name={item.Name}
                        amount={item.Current}
                        className={`${item.Current <= 50000.00 ? 'text-danger' : 'text-primary'}`}
                    />
                ))}
                <TopbarCard
                    name='Total'
                    amount={total}
                    className={`${total <= 100000 ? 'text-danger' : 'text-primary'}`}
                />
                {creditDebit.map((item, index) => (
                    <>
                        {Object.entries(item).map(([key, value], idx) => (
                            <TopbarCard
                                key={`credit-debit-${index}-${idx}`}
                                name={key}
                                amount={value}
                                className={'text-success'}
                            />
                        ))}
                    </>
                ))}
                {/* Mobile Menu Toggle */}
                {/* <div className='d-lg-none ms-2 me-n3' title='Show header menu'>
                    <div className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px' id='kt_header_menu_mobile_toggle'>
                        <span className='svg-icon svg-icon-1'>
                            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                                <path
                                    d='M13 11H3C2.4 11 2 10.6 2 10V9C2 8.4 2.4 8 3 8H13C13.6 8 14 8.4 14 9V10C14 10.6 13.6 11 13 11ZM22 5V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4V5C2 5.6 2.4 6 3 6H21C21.6 6 22 5.6 22 5Z'
                                    fill='black' />
                                <path opacity='0.3'
                                    d='M21 16H3C2.4 16 2 15.6 2 15V14C2 13.4 2.4 13 3 13H21C21.6 13 22 13.4 22 14V15C22 15.6 21.6 16 21 16ZM14 20V19C14 18.4 13.6 18 13 18H3C2.4 18 2 18.4 2 19V20C2 20.6 2.4 21 3 21H13C13.6 21 14 20.6 14 20Z'
                                    fill='black' />
                            </svg>
                        </span>
                    </div>
                </div> */}
            </div>
        </div>

    );
}

export default TopBar;
