import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import { AppContext } from "../context/AppContext";
import { useContext, useEffect, useState } from "react";
import '../Components/UI/spinner.css'

const PrivateRoute = ({ children, routeName }) => {
  const token = Cookies.get("token"); 
  const { manueList } = useContext(AppContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    
    if (manueList) {
      setLoading(false);
    }
  }, [manueList]);

  if (loading) return  (
    <div className='d-flex align-items-center justify-content-center h-100'>
        <span className="loader"></span>
    </div>
);

  if (!token) {
    toast.error("Access Denied ! Please log in to continue.", { position: "top-right" });
    return <Navigate to="/" />;
  }

  if (!manueList.some(menuItem => menuItem.ManuRoute === routeName)) {
    toast.error("You don't have access to this page!", { position: "top-right" });
    return <Navigate to="/unauthorized" />;
  }

  return children;
};

export default PrivateRoute;
