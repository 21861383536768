import React, { useContext } from "react";
import { useNavigate } from "react-router";
import { AppContext } from "../../context/AppContext";
import swal from "sweetalert";

export default function Table({ Header, transactionData, routeName }) {
  const navigate = useNavigate();
  // const name = "editTransaction";
  // console.log(transactionData);

  const { deleteHandler } = useContext(AppContext);

  const submitHandler = (id, event) => {
    event.preventDefault();
    // console.log(id);
    navigate(`/${routeName}/${id}`);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };


  const clickHandler = (id, event) => {
    event.preventDefault();
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        deleteHandler(id);
        swal("Your file has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your file is safe!", {
          icon: "info",
        });
      }
    });
  };
  return (
    <div>
      <table
        id="myTable"
        className="table table-bordered table-hover table-rounded table-row-bordered border gs-3 text-center"
      >
        {/* <tr className="fw-bold fs-6 border-bottom-2 border-gray-200"> */}
        <thead className="bg-gray-200">
          <tr className="fw-bold fs-6 border-bottom-2 border-gray-200 text-capitalize">
            {Header.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {transactionData.map((data, index) => (
            // <tr className={data.PaymentType == null ? 'bg-danger' : 'bg-success'} key={index}>
            // <tr className={data.PaymentType == null ? 'bg-danger' : 'bg-success'} key={index}>
            // <tr className={data.PaymentType == null ? 'bg-danger' : 'bg-success'} key={index}>
            <tr key={index}>
              <td>{data.Amount}</td>
              <td>{data.Note}</td>
              <td>
                {data.categoryName
                  ? data.categoryName
                  : data.PaymentType
                  ? data.PaymentType
                  : data.withdrawalOff
                  ? data.withdrawalOff
                  : "N/A"}
              </td>
              <td>{data.PaymentMethod}</td>
              {/* <td >{data.PaymentType}</td>
              <td>{data.withdrawalOff}</td> */}
              <td>{formatDate(data.Created)}</td>
              <td>{data.UserName}</td>
              <td className="text-center">
                <div className="row">
                  {/* Edit button */}
                  <form
                    onSubmit={(event) => submitHandler(data.TransactionID, event)}
                    className="col-md-6 p-0 text-end" 
                  >
                    <button
                      type="submit"
                      className="svg-icon svg-icon-success svg-icon-2hx"
                      style={{
                        backgroundColor: "transparent",
                        border: "0px",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          opacity="0.3"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z"
                          fill="black"
                        />
                        <path
                          d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z"
                          fill="black"
                        />
                        <path
                          d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z"
                          fill="black"
                        />
                      </svg>
                    </button>
                  </form>
                  {/* Delete button  */}
                  <form
                    onSubmit={(event) =>
                      clickHandler(data.TransactionID, event)
                    }
                    method="post"
                    className="col-md-6 p-0 text-start"
                  >
                    <button
                      type="submit"
                      name="delete"
                      style={{
                        backgroundColor: "transparent",
                        border: "0px",
                      }}
                    >
                      <span className="svg-icon svg-icon-danger svg-icon-2hx">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <rect
                            opacity="0.3"
                            x="2"
                            y="2"
                            width="20"
                            height="20"
                            rx="10"
                            fill="black"
                          />
                          <rect
                            x="7"
                            y="15.3137"
                            width="12"
                            height="2"
                            rx="1"
                            transform="rotate(-45 7 15.3137)"
                            fill="black"
                          />
                          <rect
                            x="8.41422"
                            y="7"
                            width="12"
                            height="2"
                            rx="1"
                            transform="rotate(45 8.41422 7)"
                            fill="black"
                          />
                        </svg>
                      </span>
                    </button>
                  </form>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
