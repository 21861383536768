import React from 'react';
// import '../assets/css/style.bundle.css';

export function Footer() {
    


    return (
            <div  className='footer '>
                <div  className='container-fluid'>
                    <div  className='row'>
                        <div  className='col-md-12 d-flex justify-content-center' style={{padding: '20px 0', fontSize:'15px'}}>
                            Powered By Unique IT Solution
                        </div>
                    </div>
                </div>
            </div>
    )
}
