import React, { useState, useEffect, useContext } from 'react';
import Chart from 'react-apexcharts';
import { AppContext } from '../../context/AppContext';
import '../UI/spinner.css'
import { AiOutlineBarChart } from 'react-icons/ai';

const LineChart = () => {
    const { token, lineChart, PieChartData } = useContext(AppContext);
    const [series, setSeries] = useState([]);

    const [options, setOptions] = useState({
        chart: {
            height: 400,
            type: 'line',
            toolbar: {
                show: true,
            },
            zoom: {
                enabled: false,
            },
        },
        title: {
            text: ' ',
            align: 'center',
        },
        colors: ['#FF4560', '#008FFB'],
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'smooth',
            width: 4,
        },
        xaxis: {
            categories: [], // Initially empty; will be updated with fetched data
            title: {
                text: 'Month',
                style: {
                    fontSize: '14px',
                    fontWeight: 600,
                },
            },
        },
        yaxis: [
            {
                title: {
                    text: 'Expenses (INR)',
                },
                min: 0,
            },
            {
                opposite: true,
                title: {
                    text: 'Income (INR)',
                },
            },
        ],
        tooltip: {
            shared: true,
            intersect: false,
        },
        grid: {
            borderColor: '#f1f1f1',
        },
    });


    const tempData = {
        token: token,
        apikey: "Unique@1234567890@",
        Month: "11",
        Year: "2024",
    };


    useEffect(() => {
        PieChartData(tempData)
        if (lineChart && lineChart.series && lineChart.categories) {
            // console.log(lineChart);
            setSeries(lineChart.series);
            setOptions(prevOptions => ({
                ...prevOptions,
                xaxis: {
                    ...prevOptions.xaxis,
                    categories: lineChart.categories,
                },
            }));
        } else {
            console.error("No line chart data available");

        }
    }, [lineChart]);



    return (
        <div style={{
            height: '450px',
            backgroundColor: '#fff',
            borderRadius: '20px',
            overflow: 'hidden',
        }}>
            {/* {Array.isArray(series) && series.length > 0 ? (
                <Chart options={options} series={series} type="line" height={450} />
            ) : (
                <div className='d-flex align-items-center justify-content-center h-100'>
                    <span className="loader"></span>
                </div>
            )} */}
            {series === null || series === undefined ? (
                <div className='d-flex align-items-center justify-content-center h-100'>
                    <span className="loader"></span>
                </div>
            ) : series.length === 0 || (series[0].data && series[0].data.length === 0) ? (
                <div className='d-flex align-items-center justify-content-center h-100 flex-column'>
                    <AiOutlineBarChart size={40} color="#ccc" style={{ marginBottom: '10px' }} />
                    <p className='fs-2' style={{ textAlign: 'center', fontSize: '18px', color: '#666' }}>
                        No data found
                    </p>
                </div>
            ) : (
                <Chart
                    options={options}
                    series={series}
                    type="line"
                    height={450}
                />
            )}

        </div>
    );
};

export default LineChart;
