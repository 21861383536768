import React from 'react';
import './spinner.css';

function Spinner() {
  return (
    <div>
        <span className="loader"></span>
    </div>
  )
}

export default Spinner