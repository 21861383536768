import React, { useCallback, useContext, useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import { AppContext } from '../../context/AppContext';
import CreditDebitTable from '../UI/CreditDebitTable';
import { BsDatabaseExclamation } from 'react-icons/bs';
import { Select } from '../UI/FormComponent';

function DebitList() {

    const {
        currentPage,
        setCurrentPage,
        debitlist
    } = useContext(AppContext);
    const [Header] = useState([
        "Amount",
        "Name",
        "CompanyName",
        "Description",
        "Email",
        "ContactNumber",
        "Created",
        "user",
        "action"
    ]);


    const [searchText, setSearchText] = useState('');
    const [selectedUser, setSelectedUser] = useState('');

    const itemsPerPage = 10;
    const [debit] = useState(debitlist);


    const filteredRows = debit.filter(row =>
        (!selectedUser || row.UserName === selectedUser) &&  // Apply user filter
        Object.values(row).some(value =>
            value !== null && value !== undefined && value.toString().toLowerCase().includes(searchText.toLowerCase())
        )
    );


    const updatePagination = (offset) => {
        const endOffset = Math.min(offset + itemsPerPage, filteredRows.length);
        setCurrentItems(filteredRows.slice(offset, endOffset));
        setPageCount(Math.ceil(filteredRows.length / itemsPerPage));
    };


    const [currentItems, setCurrentItems] = useState(filteredRows.slice(0, itemsPerPage));
    const [pageCount, setPageCount] = useState(Math.ceil(filteredRows.length / itemsPerPage));

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % filteredRows.length;
        setCurrentPage(event.selected);
        updatePagination(newOffset);
    };

    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
        const newFilteredRows = debit.filter(row =>
            Object.values(row).some(value =>
                value !== null && value !== undefined && value.toString().toLowerCase().includes(e.target.value.toLowerCase())
            )
        );
        setPageCount(Math.ceil(newFilteredRows.length / itemsPerPage));
        updatePagination(0);
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const page = parseInt(queryParams.get('page')) || 0; // Get the page from query params
        setCurrentPage(page); // Update the currentPage state with the value from the URL
        const newOffset = (page * itemsPerPage) % filteredRows.length;
        updatePagination(newOffset);
    }, [filteredRows.length, itemsPerPage, setCurrentPage]);

    const USers = [
        { id: '', Name: 'All Users' },
        { id: 'Arpit', Name: 'Arpit' },
        { id: 'Maitri', Name: 'Maitri' },
    ];
    const handleUserChange = (e) => {
        setSelectedUser(e.target.value);
    };

    return (
        <div id="kt_content_container" className="container-xxl mb-5 mt-3 mt-sm-0">
            <div className="card card-flush border-gray-300 shadow-sm">
                <div className="card-header py-0">
                    <h3 className="card-title">
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor"
                            className="bi bi-list-task" viewBox="0 0 16 16">
                            <path fillRule="evenodd"
                                d="M2 2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5H2zM3 3H2v1h1V3z" />
                            <path
                                d="M5 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM5.5 7a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 4a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9z" />
                            <path fillRule="evenodd"
                                d="M1.5 7a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5V7zM2 7h1v1H2V7zm0 3.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H2zm1 .5H2v1h1v-1z" />
                        </svg>&nbsp; Debit List
                    </h3>

                    {/* <div className="card-toolbar"> */}
                    <div className="row align-items-center">
                        <div className="col-md-4 col-sm-4 col-4">
                            <Select
                                id="UserID"
                                className={`form-select form-select cursor-pointer text-capitalize `}
                                name="useID"
                                options={USers}
                                value={selectedUser}
                                onChange={handleUserChange}
                            />
                        </div>
                        <div className="col-md-4 col-sm-4 col-4">
                            <input
                                type="text"
                                id="searchInput"
                                placeholder="Search...."
                                value={searchText}
                                onChange={handleSearchChange}
                                className="form-control form-control-solid bg-gray text-dark fs-7"
                            />
                        </div>
                        <div className="col-md-4 col-sm-4 col-4">
                            <Link to="/creditdebit" type="button" className="btn btn-sm btn-primary" >
                                ADD Credit Debit
                            </Link>
                        </div>
                    </div>
                    {/* </div> */}
                </div>

                <div className="card-body py-2">
                    <div className="table-responsive">
                        {filteredRows.length > 0 ? (
                            <CreditDebitTable
                                Header={Header}
                                transactionData={currentItems}
                                routeName="creditdebit"
                            />
                        ) : (
                            <div className="d-flex flex-column h-100vh align-items-center text-center mt-5">
                                <BsDatabaseExclamation className="fs-1 text-danger mb-3" />
                                <p className="text-danger fs-1 fw-bold mb-2">No data available</p>
                                <p className="text-muted fs-4 ">
                                    Please adjust your search or check back later for updated data.
                                </p>
                            </div>
                        )}
                    </div>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="Next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        forcePage={currentPage}
                        pageCount={pageCount}
                        previousLabel="< Previous"
                        renderOnZeroPageCount={null}
                        containerClassName="pagination"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        activeClassName="active"
                    />
                </div>
            </div>
        </div>
    )
}

export default DebitList