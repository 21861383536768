import React, { useState, useEffect, useContext } from 'react';
import Chart from 'react-apexcharts';
import { AppContext } from '../../context/AppContext';
import '../UI/spinner.css';
import { AiOutlineBarChart } from 'react-icons/ai';

function PieChart() {
    const { token, pieChart, PieChartData } = useContext(AppContext);


    const names = pieChart.map(item => item.Name);
    const quantities = pieChart.map(item => parseFloat(item.Amount));

    const options = {
        labels: names,
        legend: {
            show: true,
            position: "right"
        },
        tooltip: {
            theme: 'dark',         
            y: {
                formatter: function (value) {
                    return ` ₹ ${value}`; 
                }
            }
        },responsive: [
            {
              breakpoint: 660,        
              options: {
                chart: {
                  width: '100%'
                },
                legend: {
                    show: true,
                  position: 'right'
                }
              }
            }
          ]
          
    };

    return (

        <div style={{
            height: '400px',
            backgroundColor: '#fff',
            borderRadius: '20px',
            overflow: 'hidden',
            paddingTop:'20px'
        }}>
            {pieChart === null || pieChart === undefined ? (
                <div className='d-flex align-items-center justify-content-center h-100'>
                    <span className="loader"></span>
                </div>
            ) : pieChart.length === 0 ? (
                <div className='d-flex align-items-center justify-content-center h-100 flex-column'>
                    <AiOutlineBarChart size={40} color="#ccc" style={{ marginBottom: '10px' }} />
                    <p className='fs-2' style={{ textAlign: 'center', fontSize: '18px', color: '#666' }}>
                        No data found
                    </p>
                </div>
            ) : (
                <Chart
                    height={350}
                    type="pie"
                    series={quantities}
                    labels={{
                        show: true,
                        name: {
                            show: true
                        }
                    }}
                    options={options}
                />
            )}
        </div>


        
    );

}

export default PieChart;
